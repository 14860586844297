import React from 'react';
import PropTypes from 'prop-types';

import Image from '../../../components/Image';
import Paragraph from '../../../components/Paragraph';
import {ContentWrapper, ImageWrapper, Category, LinkWrapper} from './styles';
import Button from '../../../components/Button';
import Headline from '../../../components/Headline';

const Teaser = ({title, category, teaserImage, desc, route}) => {
    return (
        <LinkWrapper className="ap-teaser" href={route}>
            <ImageWrapper>
                <Image src={teaserImage} alt={title} />
                <Category>{category}</Category>
                <Button
                    label="Beitrag lesen"
                    icon="arrowForward"
                    iconRight
                    ghost
                />
            </ImageWrapper>
            <ContentWrapper>
                <Headline type="h6" align="left" fontWeight="bold">
                    {title}
                </Headline>
                <Paragraph>{desc}</Paragraph>
            </ContentWrapper>
        </LinkWrapper>
    );
};

Teaser.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    category: PropTypes.string,
    teaserImage: PropTypes.string,
    route: PropTypes.string,
};

export default Teaser;
