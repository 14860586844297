import React from 'react';

export default {
    headline: (
        <>
            <strong>Deine Apotique-News</strong> – Bleib auf dem Laufenden!
        </>
    ),
    paragraph1:
        'In unserer Auslage findest Du die neusten Beiträge von Apotique. Sieh Dir die Beiträge an: Es wird aufregend!',
    compiledHeadline: (
        <i>
            Für Dich <strong>zusammen&shy;gestellte Nachrichten</strong>
        </i>
    ),
    paragraph2:
        'Klick Dich durch unser Schaufenster und entdecke unsere neuesten News-Empfehlungen für Dich!',
    allListHeadline: (
        <>
            <strong>Stöbere</strong> <i>in unserer Zeitungs&shy;auslage</i>
        </>
    ),
    paragraph3:
        'Du willst mehr? Wir haben eine Menge interessanter News zu bieten. Schau unbedingt rein: Wir aktualisieren unsere Auslage regelmäßig!',
};
