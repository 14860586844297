import React from 'react';
import PropTypes from 'prop-types';

import {Column, Container, Row} from '../../../layout/Grid';
import Teaser from '../Teaser';

const List = ({news}) => {
    return (
        <>
            <Container>
                <Row>
                    {news.map((news) => (
                        <Column key={news.id} md={6} lg={4}>
                            <Teaser
                                title={news.title}
                                desc={news.teaserDesc}
                                category={news.category}
                                teaserImage={news.teaserImage}
                                route={news.route}
                                newspaper
                            />
                        </Column>
                    ))}
                </Row>
            </Container>
        </>
    );
};

List.propTypes = {
    news: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            desc: PropTypes.string,
            category: PropTypes.string,
            image: PropTypes.string,
        })
    ),
};

export default List;
