import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import Layout from '../ui/layout/Layout';
import {Section, Container} from '../ui/layout/Grid';
import Paragraph from '../ui/components/Paragraph';
import Button from '../ui/components/Button';
import List from '../ui/domains/news/List';
import Header from '../ui/layout/Header';
import Headline from '../ui/components/Headline';
import lang from '../lang/pages/news.lang';

import {selectSortListDesc, selectAllWithFocus} from '../model/news';

import NewsHero from '../assets/images/placeholder/hero/img-hero-news.png';
import ShopWindow from '../ui/layout/ShopWindow';
import NewsTeaser from '../ui/domains/news/Teaser';

export default function News() {
    const size = 6;
    const [pageSize, setPageSize] = useState(size);

    const newsArticles = useSelector(selectSortListDesc);
    const recomendedNews = useSelector(selectAllWithFocus);
    const limited = newsArticles.slice(0, pageSize);

    return (
        <Layout>
            <Header img={NewsHero} paddingTop>
                <Headline type="h1">{lang.headline}</Headline>
                <Paragraph>{lang.paragraph1}</Paragraph>
            </Header>
            <Section>
                <Container width="narrow" className="text-center">
                    <Headline type="h2">{lang.compiledHeadline}</Headline>
                    <Paragraph>{lang.paragraph2}</Paragraph>
                </Container>
            </Section>
            <Section>
                <Container width="xtra-wide">
                    <ShopWindow teasers={recomendedNews}>
                        {(article) => (
                            <NewsTeaser
                                title={article.title}
                                category={article.category}
                                teaserImage={article.teaserImage}
                                desc={article.teaserDesc}
                                route={article.route}
                            />
                        )}
                    </ShopWindow>
                </Container>
            </Section>
            <Section theme="grey">
                <Container width="narrow" className="text-center">
                    <Headline type="h3">{lang.allListHeadline}</Headline>
                    <Paragraph>{lang.paragraph3}</Paragraph>
                </Container>
                <Container width="wide">
                    <List news={limited} />
                </Container>
                <Container className="text-center">
                    <Button
                        disabled={pageSize >= newsArticles.length}
                        onClick={() => setPageSize(pageSize + size)}
                        label="Mehr anzeigen"
                    />
                </Container>
            </Section>
        </Layout>
    );
}
